<template>
  <div class="question_info_container">
    <div>
      <img class="info_avatar" alt="avatar animation" :src="`/gfx/avatars/${avatarImg}.svg`" >
    </div>
    <div>
      <h3>Hvorfor er dette trin vigtigt?</h3>
      <p class="question_info_description">{{ description }}</p>
    </div>
    <div class="info_steps">
      <div class="info_step_count">
        <router-link v-for="route of stepRoutes" :key="route" :to="route.path" class="step"  :class="[currentStep == route.meta.step ? 'active' : '']"></router-link>
      </div>
      <div>
        <Button @click="goToOverview($event)" label="Gå direkte til oversigt" class="p-button-outlined" />
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import store from "@/store/store";

export default {
  name: "QuestionInfo",
  props: {
    description: String,
  },
  setup() {

    const route = useRoute();
    const router = useRouter();
    const appRoutes = router.getRoutes();

    const stepRoutes = computed(() => {
      const stepRoutes = appRoutes.filter( route => route.meta.step )
      return stepRoutes
    })

    const currentStep = computed( () => {
      return route.meta.step
    })

    const avatarImg = computed( () => store.state.avatar )

    function goToOverview (){
      router.push('/persona-overview')
    }

    return{
      stepRoutes,
      currentStep,
      avatarImg,
      goToOverview
    }
  }
}
</script>

<style scoped>

.question_info_container{
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #1f2d40;
  padding: 20px;
}

.question_info_container .info_steps{
  margin-top: auto;
}

.question_info_container .question_info_description{
  margin-bottom: 50px;
}

.info_avatar{
  display: block;
  max-width: 200px;
  max-height: 200px;
  margin: 0px auto;
}

.info_step_count{
  bottom: 0px;
  width: 100%;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: justify;
  justify-content: space-between;
  position: relative;
  max-width: 80%;
  padding-bottom: 3rem;
}

.info_step_count .step{
  height: 1rem;
  width: 1rem;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
  transition: background-color 0.3s linear 0s;
  background-color: transparent;
  cursor: pointer;
}

.info_step_count .step.active{
  background-color: #ffffff;
}

</style>