<template>
  <div class="question_container">
    <div class="question_answers">
      <career-questions></career-questions>
    </div>
    <div class="question_info">
      <question-info :description="questionDescript" ></question-info>
    </div>
  </div>
  <navigation></navigation>
</template>

<script>
import Navigation from "@/components/Navigation";
import CareerQuestions from "@/components/questions/CareerQuestions";
import QuestionInfo from "@/components/QuestionInfo";

export default {
  name: "Career",
  components: {CareerQuestions, Navigation, QuestionInfo},
  setup(){
    const questionDescript = "Fokus på din Buyer Personas karriere og anciennitet hjælper dig til at forstå, hvad " +
        "der gør dem succesrige - og hvad de måske er bekymrede for. Brug disse detaljer til at levere indhold, der " +
        "specifikt er tilpasset dem."

    return{
      questionDescript
    }
  }
}
</script>

<style scoped>

</style>