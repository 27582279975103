<template>
  <h1>Trin 4/7: Deres karriere</h1>
  <div>
    <div>
      <h3>Hvad er deres jobtitel?</h3>
      <span class="p-float-label">
        <InputText id="jobTitle" v-model="jobTitle" type="text"/>
        <label for="jobTitle">F.eks.: Marketing Manager</label>
      </span>
    </div>
    <div>
      <h3>Hvordan bliver deres job målt?</h3>
      <span class="p-float-label">
        <InputText id="jobMeasured" v-model="jobMeasured" type="text"/>
        <label for="jobMeasured">F.eks.: Salg, antal leads, overholdelse af budgetter osv</label>
      </span>
    </div>
    <div>
      <h3>Hvem rapporterer de til?</h3>
      <span class="p-float-label">
        <InputText id="whoReportTo" v-model="whoReportTo" type="text"/>
        <label for="whoReportTo">F.eks.: CEO, salgschef, direktør osv.</label>
      </span>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";
export default {
  name: "CareerQuestions",
  components: {},
  setup(){
    const jobTitle = computed({
      get: () => store.state.jobTitle,
      set: val => {
        store.dispatch(('setJobTitle'), {title: val})
      }
    })

    const jobMeasured = computed({
      get: () => store.state.jobMeasured,
      set: val => {
        store.dispatch(('setJobMeasured'), {measured: val})
      }
    })

    const whoReportTo = computed({
      get: () => store.state.whoReportTo,
      set: val => {
        store.dispatch(('setWhoReportTo'), {title: val})
      }
    })

    return{
      jobTitle,
      jobMeasured,
      whoReportTo
    }

  }
}
</script>

<style scoped>

</style>