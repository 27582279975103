<template>
  <div class="question_nav">
    <Button label="Forrige" icon="pi pi-chevron-left" @click="navigatePrev" v-show="isFirstStep" />
    <Button label="Næste" icon="pi pi-chevron-right" iconPos="right" @click="navigateNext" />
  </div>
</template>

<script>
import {computed, getCurrentInstance} from "vue";
import { useRouter, useRoute } from 'vue-router';
import store from '../store/store';

export default {
  name: "Navigation",
  setup(){
    const internalInstance = getCurrentInstance();
    console.log(internalInstance);
    const router = useRouter();
    console.log("router:", router);
    console.log("router:", router.getRoutes());
    const route = useRoute();
    console.log("route:", route);

    const isFirstStep = computed(() => {
      if(route.meta.step === 1){
        return false
      } else {
        return true
      }
    })

    const isLastStep = computed(() => {
      if(route.meta.lastStep){
        return true
      } else {
        return false
      }
    })

    function navigateNext(){
      if(isLastStep.value){
        router.push('/persona-overview');
      } else {
        if (Object.keys(route.meta).indexOf('step') > -1) {
          const currentStep = route.meta.step
          const appRoutes = router.getRoutes()
          const nextStep = appRoutes.find(route => {
            return route.meta.step === currentStep + 1
          })
          if (nextStep) {
            store.dispatch('updateSteps', {stepPath: nextStep.path})
            router.push(nextStep.path);
          }
        }
      }
    }

    function navigatePrev(){
      if(Object.keys(route.meta).indexOf('step') > -1) {
        const currentStep = route.meta.step
        const appRoutes = router.getRoutes()
        const prevStep = appRoutes.find( route => {
          return route.meta.step === currentStep - 1
        })
        if(prevStep){
          router.push(prevStep.path);
        }
      }
    }

    return{
      navigateNext,
      navigatePrev,
      isFirstStep,
      isLastStep
    }
  }
}
</script>

<style scoped>
.question_nav{
  text-align: center;
  margin-top: 10px;
}

.question_nav button{
  margin: 0px 5px;
}

</style>